<template>
  <div v-if="account">
    <section class="my-4 d-flex">
      <img v-if="accountAuth0User.picture" class="user-image" :src="accountAuth0User.picture" width="30" height="30" alt="User avatar">
      <CircleLogoTeal logoSize="30" v-else class="user-image" alt="User avatar"/>
      <h3 class="ms-2">{{ account.first_name }} {{ account.middle_name }} {{ account.last_name }} {{ account.suffix }}</h3>
    </section>
    <section class="row">
      <div class="col-12 col-lg-4">
        <AccountData field="Account Email" v-if="account.account_email">
          <template v-slot:content>
            <SlotCopy :valueToCopy="account.account_email">
              {{ account.account_email | zbTruncate(30) }}
            </SlotCopy>
          </template>
        </AccountData>
        <AccountData field="Phone Number">
          <template v-slot:content>
            <SlotCopy :valueToCopy="account.account_phone">
              {{ account.account_phone }}
            </SlotCopy>
            <span class="verified-phone" v-if="isPhoneVerified">Verified</span>
          </template>
        </AccountData>
        <AccountData field="Client Since" >
          <template v-slot:content v-if="account.created_datetime">
            {{ account.created_datetime | moment('MM/DD/YYYY h:mma') }}
          </template>
        </AccountData>
        <AccountData field="Last Log in">
          <template v-slot:content v-if="accountAuth0User.last_login">
            {{ accountAuth0User.last_login | moment('MM/DD/YYYY h:mma') }}
          </template>
        </AccountData>
        <AccountData field="Salesforce">
          <template v-slot:content>
            <a :href="accountSalesforceUrl" target="_blank">Account</a> | <a :href="contactSalesforceUrl" target="_blank">Contact</a>
          </template>
        </AccountData>
      </div>
      <div class="col-12 col-lg-4">
        <AccountData field="Entities">
          <template v-slot:content>
            <BusinessEntityMultiSelect v-if="businessEntities.length" @selectedElements="selectedElements"/>
            <div v-else>No Entities</div>
          </template>
        </AccountData>
        <AccountData field="Compliance Soon">
          <template v-slot:content>
            <div class="d-flex">
              {{ complianceSoon }}
              <div v-if="getWorryFreeServiceStatus" :content="getWorryFreeServiceStatus" v-tippy class="text-black">
                <WorryFreeIcon />
              </div>
            </div>
          </template>
        </AccountData>
        <AccountData field="Compliance Overdue" :content="complianceOverdue" />
        <AccountData field="Next Billing Event">
          <template v-slot:content v-if="nextBillingEvent">
            {{ nextBillingEvent | moment('MM/DD/YYYY h:mma') }}
          </template>
        </AccountData>
        <AccountData field="Service of Process" :content="serviceOfProcess" />
        <AccountData field="Authorized Signer" :content="authorizedSignatory" />
      </div>
      <div class="col-12 col-lg-4">
        <template v-if="isProduction">
          <AccountData field="Microsoft Clarity">
            <template v-slot:content>
              <a :href="dashboardClarityUrl" target="_blank">Dashboard</a> | 
              <a :href="recordingsClarityUrl" target="_blank">Recordings</a> | 
              <a :href="heatmapsClarityUrl" target="_blank">Heatmaps</a>
            </template>
          </AccountData>
          <AccountData field="MixPanel">
            <template v-slot:content>
              <a :href="activityMixPanelUrl" target="_blank">Activity</a>
            </template>
          </AccountData>
          <AccountData field="Stripe">
            <template v-slot:content>
              <a :href="merchantStripeUrl" target="_blank">Merchant</a>
            </template>
          </AccountData>
        </template>
        <AccountData field="LaunchDarkly">
          <template v-slot:content>
            <a :href="contextsLaunchDarklyUrl" target="_blank">User Contexts</a>
          </template>
        </AccountData>
        <AccountData field="Sentry">
          <template v-slot:content>
            <a :href="discoverSentryUrl" target="_blank">Discover</a> | <a :href="issuesSentryUrl" target="_blank">Issues</a>
          </template>
        </AccountData>
        <AccountData field="Unit">
          <template v-slot:content>
            <a :href="applicationsUnitUrl" target="_blank">Applications</a>
          </template>
        </AccountData>
        <AccountData field="GCP Logs">
          <template v-slot:content>
            <a :href="logsEmailAndUserUrl" target="_blank">Email & User</a> | <a :href="logsAccountUuidUrl" target="_blank">Account</a> | <a :href="logsAllUrl" target="_blank">All</a>
          </template>
        </AccountData>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import manifest from '../../manifest';
import { textMixin, computeFullNameOfPersonMixin } from '@/components/mixins';
import AccountData from '@/components/common/Generics/AccountData';
import BusinessEntityMultiSelect from '@/components/common/Generics/BusinessEntityMultiSelect';
import CircleLogoTeal from '@/components/common/images/CircleLogoTeal.vue';
import WorryFreeIcon from '@/components/common/Generics/WorryFreeIcon'
import SlotCopy from "@/components/v2/common/Generics/SlotCopy.vue";
import {
  dashboardClarityUrl,
  recordingsClarityUrl,
  heatmapsClarityUrl,
  activityMixPanelUrl,
  contextsLaunchDarklyUrl,
  discoverSentryUrl,
  issuesSentryUrl,
  applicationsUnitUrl,
  merchantStripeUrl,
  logsEmailAndUserUrl,
  logsAccountUuidUrl,
  logsAllUrl
} from '@/components/utils/dynamicUrls.js';

export default {
  name: 'AccountDetail',

  components: {
    SlotCopy,
    AccountData,
    BusinessEntityMultiSelect,
    CircleLogoTeal,
    WorryFreeIcon
  },

  mixins: [textMixin, computeFullNameOfPersonMixin],

  data() {
    return {
      selectedEntities: []
    }
  },

  computed: {
    ...mapState({
      account: (state) => state.accounts.account,
      businessEntities: (state) => state.businessEntities.businessEntities,
      auth0User: (state) => state.accounts.auth0User,
      complianceEvents: (state) => state.businessEntities.complianceEvents,
      orderTasks: (state) => state.orders.orderTasks,
      documents: (state) => state.businessEntities.documents || [],
      users: (state) => state.accounts.users
    }),
    isProduction() {
      return manifest.environment === 'production';
    },
    ...mapGetters({
      accountUuid: 'accounts/getAccountUuid'
    }),

      // TODO : When multiple users support is implemented , find the user that has the account owner role and return id
    accountUserUuid() {
      // this assumes 1:1 account-user mapping,  will change.
      if (this.users?.length>0) {
        return this.users[0].user_uuid;
      }
      return "-";
    },
    accountEmail() {
      return this.account?.account_email || '';
    },
    accountSalesforceUrl () {
      return `${manifest.clients.salesforce.host}lightning/r/${this.account.account_sf_id}/view`;
    },
    contactSalesforceUrl () {
      return `${manifest.clients.salesforce.host}lightning/r/${this.account.contact_sf_id}/view`;
    },
    dashboardClarityUrl() {
      return dashboardClarityUrl(this.accountUserUuid);
    },
    recordingsClarityUrl() {
      return recordingsClarityUrl(this.accountUserUuid);
    },
    heatmapsClarityUrl() {
      return heatmapsClarityUrl(this.accountUserUuid);
    },
    activityMixPanelUrl() {
      return activityMixPanelUrl(this.accountUserUuid);
    },
    merchantStripeUrl() {
      return merchantStripeUrl(this.accountEmail);
    },
    contextsLaunchDarklyUrl() {
      return contextsLaunchDarklyUrl(this.accountUserUuid, this.isProduction);
    },
    discoverSentryUrl() {
      return discoverSentryUrl(this.accountUserUuid, this.accountEmail);
    },
    issuesSentryUrl() {
      return issuesSentryUrl(this.accountEmail);
    },
    applicationsUnitUrl() {
      return applicationsUnitUrl(this.accountEmail, this.isProduction);
    },
    logsEmailAndUserUrl() {
      return logsEmailAndUserUrl(this.accountEmail, this.accountUserUuid, this.isProduction);
    },
    logsAccountUuidUrl() {
      return logsAccountUuidUrl(this.accountUuid, this.isProduction);
    },
    logsAllUrl() {
      return logsAllUrl(this.accountEmail, this.accountUserUuid, this.accountUuid, this.isProduction);
    },
    accountAuth0User () {
      return this.auth0User[0] ?? {};
    },
    complianceSoon () {
      return this.complianceEvents.filter(
        (event) => (
          this.selectedEntities.some(
            (entity) => entity.business_entity_uuid === event.business_entity_uuid
          ) && Date.parse(event.event_date) >= Date.now()
        )
      ).length;
    },
    getWorryFreeServiceStatus() {
      // search in the complete list of order tasks if there is someone with
      // type worry free service
      const worryFreeService = this.orderTasks.filter(
        (orderTask) => this.selectedEntities.some(
          (entity) => entity.business_entity_uuid === orderTask.business_entity_uuid
        ) && orderTask.order_task_type.toLowerCase() === 'worry_free_service'  && orderTask.order_task_status === 'active'
      );

      // if there are selected many entities but only one has WFC active
      // the name should be shown in the tooltip
      const bes = new Set(worryFreeService.map((entity) => entity.business_entity_uuid));

      if (bes.size === 0) {
        return '';
      }

      if (bes.size === 1) {
        const business_entity_name = this.selectedEntities.find(
          (entity) => bes.has(entity.business_entity_uuid)
        ).business_entity_name;

        return `${business_entity_name} has an active Worry Free Compliance service`;
      }
      return 'One or more companies have an active Worry Free Compliance service';
    },
    complianceOverdue () {
      return this.complianceEvents.filter(
        (event) => (
          this.selectedEntities.some(
            (entity) => entity.business_entity_uuid === event.business_entity_uuid
          ) && Date.parse(event.event_date) < Date.now()
        )
      ).length;
    },
    nextBillingEvent () {
      if (this.orderTasks.length) {
        const nextBillingDate = this.orderTasks.reduce(
          (a, b) => {
            if (!a.term_end_date && b.term_end_date >= Date.now()) return b;
            if (!b.term_end_date && a.term_end_date >= Date.now()) return a;
            if (a.term_end_date <= b.term_end_date) return a;
            return b;
          }
        )
        if (nextBillingDate) {
          return nextBillingDate.term_end_date;
        }
      }
      return undefined;
    },
    serviceOfProcess () {
      return this.documents.filter(
        (document) => (
          this.selectedEntities.some(
            (entity) => entity.business_entity_uuid === document.business_entity_uuid
          )
          && document.subcategory.toUpperCase() === 'SOP' ? 1 : 0
        )
      ).length;
    },
    authorizedSignatory () {
      if (this.businessEntities.length && this.selectedEntities.length === 1) {
        if (this.selectedEntities[0].business_entity_data && this.selectedEntities[0].business_entity_data.authorized_signatory) {
          const authSignatory = this.selectedEntities[0].business_entity_data.authorized_signatory;
          let authorizedSignatoryFullName = `${this.computeFullNameOfPerson(authSignatory)}`;
          if (authorizedSignatoryFullName === '') {
            return 'No Authorized Signatory';
          }
          if (authSignatory.title) {
            authorizedSignatoryFullName += `, ${authSignatory.title}`
          }
          return authorizedSignatoryFullName
        }
        return 'No Authorized Signatory';
      }
      return 'Select an entity';
    },
    isPhoneVerified () {
      if (this.account.phone_validation) {
        return ['connected', 'connected-75'].includes(this.account.phone_validation.status);
      }
      return false;
    }
  },

  methods: {
    selectedElements (entities) {
      this.selectedEntities = entities;
    }
  }
}
</script>

<style lang="scss" scoped>
  a {
    color: $clr-primary-dk;
  }
  .user-image {
    border-radius: 50%;
  }
  .verified-phone {
    cursor: auto;
    font-size: rem(7.5);
    color: $clr-primary-dkr;
    background: $clr-primary-ltr;
    padding: 4px;
    border-radius: 15px;
  }
</style>
