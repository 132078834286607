<template>
  <div class="account-data row mb-2">
    <div class="field col-12 col-sm-5" v-if="field !== undefined">{{ field }}</div>
    <div class="field col-12 col-sm-5" v-else>
      <slot name="field"></slot>
    </div>
    <div class="content col-12 col-sm-7" v-if="content !== undefined">{{ content | zbTruncate(30) }}</div>
    <div class="content col-12 col-sm-7" v-else>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { textMixin } from '@/components/mixins';

export default {
  name: 'AccountData',

  mixins: [textMixin],

    props: {
    field: {
      type: String,
      default: undefined
    },
    content: {
      type: [String, Number],
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
  .account-data {
    .field {
      font-size: rem(14);
      font-weight: 600;
      line-height: em(18, 14);
      letter-spacing: em(.16, 14);
      color: $stone-1;
    }
    .content {
      font-size: rem(14);
      font-weight: 400;
      line-height: em(18, 14);
      letter-spacing: em(.16, 14);
      color: $stone-1;
    }
  }
</style>
