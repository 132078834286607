<template>
  <div class="worry-free-icon">
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" fill="none" viewBox="0 0 16 16">
      <path class="external-stroke" stroke-linecap="round" stroke-linejoin="round" d="M8 10A4.667 4.667 0 108 .667 4.667 4.667 0 008 10z"/>
      <path class="external-stroke" stroke-linecap="round" stroke-linejoin="round" d="M5.473 9.26l-.806 6.073 3.333-2 3.333 2-.806-6.08"/>
      <path class="internal-stroke" stroke-linecap="round" stroke-linejoin="round" d="M6 5.333l1.333 1.334L10 4"/>
    </svg>  
  </div>
</template>

<script>

export default {
  name: 'WorryFreeIcon',

  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style lang="scss" scoped>
  .external-stroke {
    stroke: $stone-2 
  }
  .internal-stroke {
    stroke: $zeal-20
  }
</style>
