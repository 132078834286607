<template>
  <tr>
    <td>
      <template v-if="showOrderTasksHelper">
        <md-icon v-tippy="getVTippyInformation">view_list</md-icon>
        &nbsp;
      </template>
      <router-link :to="{ name: 'Order', params: { uuid: order.order_uuid } }">{{ order.purchase_date | moment("MMMM DD YYYY") }}</router-link>
    </td>
    <td>{{ jurisdiction }}</td>
    <td><span class="badge" v-bind:class="status">{{ order.order_status }}</span></td>
    <td>{{ order.fulfillment_level }}</td>
    <td>{{ order.api_version }}</td>
    <td><a :href="opportunitySalesforceUrl" target="_blank">Opportunity</a></td>
  </tr>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import manifest from '../../manifest';

export default {
  name: 'OrderListItem',
  props: {
    order: {
      required: true
    },
    showOrderTasksHelper: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState(['successAlert']),
    ...mapGetters({getOrderTasksByOrder: 'orders/getOrderTasksByOrder'}),
    status() {
      if (this.order.order_status === 'incomplete') {
        return {
          'bg-secondary': true
        };
      } if (this.order.order_status === 'canceled') {
        return {
          'bg-danger': true
        };
      }
      return {
        'bg-info': true
      };
    },
    jurisdiction() {
      return this.order.jurisdictions.map((item) => item).join(', ');
    },
    opportunitySalesforceUrl() {
      return `${manifest.clients.salesforce.host}lightning/r/${this.order.opportunity_sf_id}/view`;
    },
    getVTippyInformation() {
      const messages = [];
      const orderTasks = this.getOrderTasksByOrder(this.order.order_uuid);
      for(const ot of orderTasks) {
        messages.push(`${ot.order_task_type}`);
      }
      const message = messages.join('<br/>');
      const config = {
      'arrow': true,
      'allowHTML': true,
      'content': message
      };
      return config;
    }
  },
};
</script>

<style lang="scss">
  .badge {
    text-transform: capitalize;
  }
</style>
