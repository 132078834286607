<template>
  <div class="multi-select" v-click-outside="handleFocusOut">
    <div @click="showElements" class="multi-select-header">
      {{ header }}
      <i class="fas fa-chevron-down ms-1 rotate" :class="{'rotate-on-click': show}"></i>
    </div>
    <div v-if="show" class="multi-select-items mt-2">
      <div class="search-section p-2 d-flex">
        <i class="fas fa-search me-1"></i>
        <input
          class="search-section-input" type="text" name="search" placeholder="Search"
          v-model="search" @input="filterElements" autocomplete="off">
      </div>
      <div class="selection p-2">
        <div class="selection-item mb-1">
          <input type="checkbox" name="check-all" class="zb-checkbox me-2" :checked="allChecked" @change="checkAll">
          {{ selectionText }}
        </div>
        <div class="selection-item mb-1" v-for="(element, index) in filteredElements" :key="index">
          <input type="checkbox" :name="element.business_entity_uuid" class="zb-checkbox me-2" :checked="isElementChecked(element.business_entity_uuid)" @change="editCheckedElements(element.business_entity_uuid)">
          <div>
            <SlotCopy :valueToCopy="element.business_entity_name">
              {{ element.business_entity_name }}
            </SlotCopy>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SlotCopy from "@/components/v2/common/Generics/SlotCopy.vue";

export default {
  name: 'BusinessEntityMultiSelect',
  components: {SlotCopy},

  data() {
    return {
      show: false,
      search: '',
      filteredElements: [],
      checkedElements: []
    };
  },

  computed: {
    ...mapState({
      businessEntities: (state) => state.businessEntities.businessEntities
    }),
    allChecked () {
      return this.businessEntities.length === this.checkedElements.length;
    },
    selectionText () {
      if (this.allChecked) {
        return 'Unselect All';
      }
      return 'Select All';
    },
    header () {
      if (this.businessEntities.length > 1) {
        return `All (${this.businessEntities.length})`
      }
      return `${this.businessEntities[0].business_entity_name}`
    }
  },

  methods: {
    filterElements () {
      this.filteredElements = this.businessEntities.filter(element => {
        return element.business_entity_name.trim().toLowerCase().indexOf(this.search.toLowerCase()) >= 0;
      })
    },
    showElements () {
      this.show = !this.show;
      this.filteredElements = [...this.businessEntities];
    },
    handleFocusOut () {
      this.show = false;
    },
    editCheckedElements (uuid) {
      if (this.checkedElements.includes(uuid)) {
        this.checkedElements = this.checkedElements.filter(element_uuid => element_uuid !== uuid);
      } else {
        this.checkedElements.push(uuid);
      }
      this.emitSelectedElements();
    },
    checkAll () {
      if (this.businessEntities.length === this.checkedElements.length) {
        this.checkedElements = [];
      } else {
        this.businessEntities.forEach(businessEntity => {
          if (!this.checkedElements.includes(businessEntity.business_entity_uuid)) {
            this.checkedElements.push(businessEntity.business_entity_uuid);
          }
        });
      }
      this.emitSelectedElements();
    },
    isElementChecked(uuid) {
      return this.checkedElements.includes(uuid);
    },
    emitSelectedElements() {
      const selectedElements = this.businessEntities.filter(businessEntity => this.checkedElements.includes(businessEntity.business_entity_uuid));
      this.$emit('selectedElements', selectedElements);
    }
  },

  mounted() {
    this.checkAll();
  }
}
</script>

<style lang="scss" scoped>
  .multi-select-header {
    cursor: pointer;
  }
  .multi-select-items {
    box-shadow: 0px 0px 2px 0px #afafaf;
    position: absolute;
    background: white;
    z-index: 1;
    width: 100%;
  }
  .search-section {
    border: 1px solid #EEEEEE;
  }
  .search-section-input {
    width: 100%;
    height: 25px;
    border: none;
  }
  .fas {
    -webkit-text-stroke: 1px white;
  }
  .fa-search {
    padding-top: 6px;
  }
  .rotate {
    transition: transform .3s ease-in-out;
  }
  .rotate-on-click {
    transform: rotateZ(-180deg);
  }
</style>
